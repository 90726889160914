import React from 'react'
import styled from 'styled-components'
import { SPACE } from 'Theme'
import { Flex, Grid } from 'components/Layout'
import img0 from 'images/photos/course-content0.png'
import img1 from 'images/photos/course-content1.png'
import img2 from 'images/photos/course-content2.png'
import img3 from 'images/photos/course-content3.png'
import { DesktopOnly, MobileOnly } from 'components/ResponsiveDisplay'
import GalleryModal, { useGalleryModal } from 'routes/reviews/GalleryModal'

const IMAGES = [img0, img1, img2, img3]

const Photo = styled('img')`
  width: 100%;
  height: auto;
`

const PhotoSmall = styled('img')`
  display: block;
  margin-left: auto;
  width: 90%;
`

const PreviewGallery = () => {
  const [activeImgIndex, setActiveImgIndex] = React.useState(0)
  const { isModalOpen, openModal, closeModal } = useGalleryModal()

  const openImgModal = (imageIndex) => {
    setActiveImgIndex(imageIndex)
    openModal()
  }

  const closeImgModal = () => {
    closeModal()
    setActiveImgIndex(0)
  }

  return (
    <>
      <MobileOnly>
        <GalleryModal
          isOpen={isModalOpen}
          closeModal={() => {
            closeImgModal()
          }}
          image={IMAGES[activeImgIndex]}
          alt="Obsah Kurzu"
        />
        <Grid gridTemplateColumns="1fr 1fr" gridGap={SPACE.M} mb={SPACE.XL}>
          {IMAGES.map((image, index) => (
            <Photo
              src={image}
              alt="Obsah kurzu"
              key={index}
              onClick={() => {
                openImgModal(index)
              }}
            />
          ))}
        </Grid>
      </MobileOnly>
      <DesktopOnly>
        <Grid gridTemplateColumns="3fr 1fr" mb={SPACE.XL}>
          <Photo src={IMAGES[activeImgIndex]} alt="Obsah kurzu" />
          <Flex
            flexDirection="column"
            gridGap={SPACE.L}
            justifyContent="space-between"
          >
            {IMAGES.slice(1).map((image, index) => (
              <div key={index}>
                <PhotoSmall
                  src={image}
                  alt="Obsah kurzu"
                  onMouseEnter={() => setActiveImgIndex(index + 1)}
                  onMouseLeave={() => setActiveImgIndex(0)}
                />
              </div>
            ))}
          </Flex>
        </Grid>
      </DesktopOnly>
    </>
  )
}

export default PreviewGallery
