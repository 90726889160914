import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mq, SPACE, COLOR, FONT_SIZE } from 'Theme'
import { rem } from 'polished'
import { Flex } from 'components/Layout'
import Gain from 'images/svg/gain.inline.svg'

const StyledGraphList = styled('div')`
  display: grid;
  grid-gap: ${SPACE.L};
  ${mq.from.M`
    grid-template-columns: 1fr 1fr;
  `}
`

const Title = styled('p')`
  text-transform: uppercase;
  margin-bottom: ${rem(4)};
  ${mq.from.M`
    font-size: ${FONT_SIZE.M};
  `}
`

const BarWrapper = styled('div')`
  flex: 1;
`

const Bar = styled('div')`
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 1px;
  margin-bottom: 3px;
  background-color: ${COLOR.BLACK_OUT};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    border-radius: 1px;
  }
`

const GrayBar = styled(Bar)`
  &::after {
    background-color: ${COLOR.DARK_GRAY};
    width: ${({ base }) => (base ? `${base}%` : '0px')};
  }
`

const RedBar = styled(Bar)`
  &::after {
    background-color: ${COLOR.PRIMARY_RED};
    width: ${({ result }) => (result > 100 ? `100%` : `${result}%`)};
  }
`

const GainWrapper = styled(Flex)`
  margin-left: ${SPACE.M};
  grid-gap: ${SPACE.S};
  color: ${COLOR.PRIMARY_RED};
`

const GraphList = ({ items }) => (
  <StyledGraphList>
    {items.map(({ title, base, result }, index) => (
      <div key={index}>
        <Title>{title}</Title>
        <Flex>
          <BarWrapper>
            <GrayBar base={base} />
            <RedBar result={result} />
          </BarWrapper>
          <GainWrapper alignItems="center">
            <span>+{result - base}%</span>
            <Gain />
          </GainWrapper>
        </Flex>
      </div>
    ))}
  </StyledGraphList>
)

GraphList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      base: PropTypes.number.isRequired,
      result: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

export default GraphList
