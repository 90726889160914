import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mq, SPACE, COLOR, RADIUS } from 'Theme'
import { Flex } from 'components/Layout'

const Wrapper = styled(Flex)`
  justify-content: space-around;
  margin-bottom: ${SPACE.L};

  ${mq.from.M`
    justify-content: flex-start;
    grid-gap: ${SPACE.L};
  `}
`

const Text = styled('p')`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: ${SPACE.S};

  color: ${({ isRed }) => (isRed ? COLOR.PRIMARY_RED : COLOR.DARK_GRAY)};

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: ${RADIUS.CIRCLE};
    background-color: ${({ isRed }) =>
      isRed ? COLOR.PRIMARY_RED : COLOR.DARK_GRAY};
  }

  ${mq.from.M`
    flex-direction: row;
    grid-gap: ${SPACE.M};    
  `}
`

const GraphLegend = ({ gray, red }) => (
  <Wrapper>
    <Text>{gray}</Text>
    <Text isRed>{red}</Text>
  </Wrapper>
)

GraphLegend.propTypes = {
  gray: PropTypes.string.isRequired,
  red: PropTypes.string.isRequired,
}

export default GraphLegend
