import React from 'react'
import styled from 'styled-components'
import { COLOR, FONT_SIZE, SPACE, RADIUS, mq } from 'Theme'
import { Grid } from 'components/Layout'
import Mural from 'images/svg/mural.inline.svg'
import Trello from 'images/svg/trello.inline.svg'
import Koan from 'images/svg/koan.inline.svg'
import Tableau from 'images/svg/tableau.inline.svg'
import Figma from 'images/svg/figma.inline.svg'
import RestApi from 'images/svg/rest.inline.svg'
import { Text } from 'components/Typography'

const Wrapper = styled('fieldset')`
  height: max-content;
  padding: ${SPACE.L};
  border-color: ${COLOR.SQUANT};
  border-radius: ${RADIUS.M};
`

const Title = styled('legend')`
  margin: 0 auto;
  padding: 0 ${SPACE.M};
  text-align: center;

  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: ${FONT_SIZE.S};
  ${mq.from.M`
    letter-spacing: 1px;
  `}
`

const Item = styled('a')`
  color: ${COLOR.WHITE};
  font-size: ${FONT_SIZE.M};
  text-decoration: none;
`

const Tools = () => (
  <Wrapper>
    <Title>Používané nástroje počas kurzu</Title>
    <Grid
      gridTemplateColumns="1fr 1fr 1fr"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gridGap={SPACE.XM}
    >
      <Item target="_blank" href="https://www.mural.co/">
        <Mural />
        <Text as="p" mt={SPACE.S}>
          Mural
        </Text>
      </Item>
      <Item target="_blank" href="https://trello.com/">
        <Trello />
        <Text as="p" mt={SPACE.S}>
          Trello
        </Text>
      </Item>
      <Item target="_blank" href="https://www.koan.co/">
        <Koan />
        <Text as="p" mt={SPACE.S}>
          Koan
        </Text>
      </Item>
      <Item target="_blank" href="https://www.tableau.com/">
        <Tableau />
        <Text as="p" mt={SPACE.S}>
          Tableau
        </Text>
      </Item>
      <Item target="_blank" href="https://www.figma.com/">
        <Figma />
        <Text as="p" mt={SPACE.S}>
          Figma
        </Text>
      </Item>
      <Item target="_blank" href="https://exchangeratesapi.io/">
        <RestApi />
        <Text as="p" mt={SPACE.S}>
          REST API
        </Text>
      </Item>
    </Grid>
  </Wrapper>
)

export default Tools
