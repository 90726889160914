import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FONT_SIZE, SPACE, mq, MAX_CONTENT_WIDTH } from 'Theme'
import { H1 } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import { rem } from 'polished'
import VideoPlayer from 'components/VideoPlayer'
import videoThumbnail from 'images/photos/reviews-video-thumbnail.jpg'
import Rating from 'components/Rating'
import Star from 'images/svg/star.svg'

const TOP_OFFSET = {
  MOBILE: rem(110),
  DESKTOP: rem(130),
}

const StyledVideoPlayer = styled(VideoPlayer)`
  ${mq.from.M`
    width: 60%;
    max-width: ${MAX_CONTENT_WIDTH.ARTICLE};
    margin: 0 auto;
  `}
`

const StyledRating = styled(Rating)`
  text-align: center;
  padding: 0 ${SPACE.M};
  margin-top: ${SPACE.L};
  font-size: ${FONT_SIZE.XL};
  ${mq.from.L`
    margin-top: ${SPACE.XL};
    font-size: ${FONT_SIZE.XXL};
  `}
`

const StyledStar = styled('img')`
  width: auto;
  height: 18px;
  margin-left: ${SPACE.S};

  ${mq.from.L`
    height: 28px;
  `}
`

const Hero = ({ videoUrl }) => (
  <Box
    pt={{ S: TOP_OFFSET.MOBILE, M: TOP_OFFSET.DESKTOP }}
    pb={{ S: SPACE.M, M: SPACE.L, L: SPACE.XXL }}
    textAlign="center"
  >
    <Flex flexDirection="column" alignContent="center" justifyContent="center">
      <H1 mb={{ S: SPACE.L, M: SPACE.XL }}>Povedali o nás</H1>
      <StyledVideoPlayer thumbnailUrl={videoThumbnail} videoUrl={videoUrl} />
      <StyledRating icon={<StyledStar src={Star} alt="Rating Star" />} />
    </Flex>
  </Box>
)

Hero.propTypes = {
  videoUrl: PropTypes.string.isRequired,
}

export default Hero
