import React from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'
import Modal, { useModal } from 'components/Modal'
import { Flex } from 'components/Layout'
import { COLOR } from 'Theme'

const GalleryModal = ({ image, alt, ...props }) => (
  <Modal
    {...props}
    ariaLabel="Gallery modal"
    width={`min(100%, ${rem(400)})`}
    height="max-content"
    btnBgColor={COLOR.BLACK}
    isTransparentMobile
  >
    <Flex alignItems="center" justifyContent="center">
      <img src={image} alt={alt} />
    </Flex>
  </Modal>
)

GalleryModal.propTypes = {
  image: PropTypes.node.isRequired,
  alt: PropTypes.string.isRequired,
}

export { GalleryModal as default, useModal as useGalleryModal }
