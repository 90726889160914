import React from 'react'
import styled from 'styled-components'
import { FONT_SIZE, SPACE } from 'Theme'
import videoCallPhoto from 'images/photos/video-call.jpg'
import { Text } from 'components/Typography'
import { Grid } from 'components/Layout'
import Tools from './Tools'

const Photo = styled('img')`
  width: 100%;
  height: auto;
`

const PreviewInfo = () => (
  <Grid gridTemplateColumns={{ S: '1fr', M: '1fr 1fr' }} gridGap={SPACE.XL}>
    <div>
      <Photo src={videoCallPhoto} alt="Group video call" />
      <Text
        as="p"
        mt={SPACE.L}
        textAlign="center"
        lineHeight="2"
        fontSize={FONT_SIZE.M}
      >
        Malá skupina študentov zaručí priestor na individuálny prístup lektorov,
        interakciu s ostatnými študentmi a kvalitné diskusie
      </Text>
    </div>
    <Tools />
  </Grid>
)

export default PreviewInfo
